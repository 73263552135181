import axios from 'axios';
import { FETCH_PHARMACY_START, FETCH_PHARMACY_SUCCESS, FETCH_PHARMACY_ERROR } from "../actionTypes";

const apiURL = process.env.REACT_APP_API_URL + "pharmacy";

export const fetchPharmacyStart = () => {
    return {
        type: FETCH_PHARMACY_START
    };
};

export const fetchPharmacySuccess = pharmacies => {
    return {
        type: FETCH_PHARMACY_SUCCESS,
        data: pharmacies
    };
};

export const fetchPharmacyError = () => {
    return {
        type: FETCH_PHARMACY_ERROR
    };
};

export const fetchPharmacy = () => {
    return dispatch => {
        dispatch(fetchPharmacyStart());
        return axios.get(apiURL)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else dispatch(fetchPharmacySuccess(res.data));
            })
            .catch(() => dispatch(fetchPharmacyError()));
    }
};