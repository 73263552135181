// Import dependencies
import React from 'react';
import connect from "react-redux/es/connect/connect";
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import history from './history';

// Import pages
import Home from "./pages/HomePage/Home";
import Catalog from "./pages/CatalogPage/Catalog";
import Calculator from "./pages/CalculatorPage/Calculator";
import Survey from "./pages/SurveyPage/Survey";
import Pharmacy from "./pages/PharmacyPage/Pharmacy";
import Login from "./pages/LoginPage/Login";
import Logout from "./pages/LogoutPage/Logout";
import NoMatch from "./pages/NoMatchPage/NoMatch";

// Import components
import Header from "./components/HeaderComponent/Header";

// Import resources
import './App.css';

function PrivateRoute({ component: Component, authenticated, ...rest }) {
    return (
        <Route
            { ...rest }
            render={ props => !!authenticated
                ? <Component { ...props } />
                : <Redirect to={ { pathname: '/login', state: { from: history.location } } } /> }
        />
    )
}

function App(props) {
    const { token } = props;
    return (
        <Router history={ history }>
            <Header />
            <div className="App container">
                <Switch>
                    <PrivateRoute exact path="/" component={ Home } authenticated={ token } />
                    <PrivateRoute path="/catalog" component={ Catalog } authenticated={ token } />
                    <PrivateRoute path="/calculator" component={ Calculator } authenticated={ token } />
                    <PrivateRoute path="/pharmacy" component={ Pharmacy } authenticated={ token } />
                    <PrivateRoute path="/survey" component={ Survey } authenticated={ token } />
                    <Route path="/login" component={ Login } />
                    <Route path="/logout" component={ Logout } />
                    <Route component={ NoMatch } />
                </Switch>
            </div>
        </Router>
    );
}

export default connect((state) => ({
    token: state.login.token
}), null)(App);