import React from 'react';
import { Link } from 'react-router-dom';

import './Card.css';

export default function Card(props) {
    const { background, title, url } = props;
    return (
        <div className="card">
            <Link to={ url }>
                <div className="card-image">
                    <img src={ background } alt="background" />
                    <span className="card-title black-text">{ title }</span>
                </div>
            </Link>
        </div>
    );
}