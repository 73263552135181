import { FETCH_PRODUCT_START, FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_ERROR } from "../actionTypes";

const initialState = {
    products: [],
    loadingInProgress: true,
    loadingError: false
};

export default function ProductReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PRODUCT_START:
            return {
                ...state,
                loadingInProgress: true,
                loadingError: false
            };
        case FETCH_PRODUCT_SUCCESS:
            return {
                ...state,
                products: action.data,
                loadingInProgress: false,
                loadingError: false
            };
        case FETCH_PRODUCT_ERROR:
            return {
                ...state,
                loadingInProgress: false,
                loadingError: true
            };
        default:
            return state;
    }
}