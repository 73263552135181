import axios from 'axios';
import { ADD_SURVEY_START, ADD_SURVEY_SUCCESS, ADD_SURVEY_ERROR, RESET_SURVEY } from '../actionTypes';

const apiURL = process.env.REACT_APP_API_URL + "survey";

export const saveSurveyStart = () => {
    return {
        type: ADD_SURVEY_START
    };
};

export const saveSurveySuccess = () => {
    return {
        type: ADD_SURVEY_SUCCESS
    };
};

export const saveSurveyError = () => {
    return {
        type: ADD_SURVEY_ERROR
    };
};

export const saveSurvey = survey => {
    return dispatch => {
        dispatch(saveSurveyStart());
        return axios.post(apiURL, survey)
            .then(res => {
                if (res.status !== 201) {
                    throw new Error("Error occured");
                } else dispatch(saveSurveySuccess());
            })
            .catch(() => dispatch(saveSurveyError()));
    }
};

export const resetSurvey = () => {
    return {
        type: RESET_SURVEY
    };
};