import { LOGIN_START, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from "../actionTypes";

const initialState = {
    token: localStorage.getItem('TOKEN'),
    authenticationInProgress: false,
    authenticationError: false
};

export default function UserReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_START:
            return {
                ...state,
                authenticationInProgress: true,
                authenticationError: false
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('TOKEN', action.data);
            return {
                ...state,
                token: action.data,
                authenticationInProgress: false,
                authenticationError: false
            };
        case LOGIN_ERROR:
            return {
                ...state,
                authenticationInProgress: false,
                authenticationError: true
            };
        case LOGOUT:
            localStorage.setItem('TOKEN', '');
            return {
                ...state,
                token: ''
            };
        default:
            return state;
    }
}