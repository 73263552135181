import React from 'react';

import Card from '../../components/CardComponent/Card'

import catalogIcon from './file.png';
import calculatorIcon from './budget.png';
import pharmacyIcon from './pharmacy.png';
import formsIcon from './checklist.png';

export default function Home() {
    return (
        <div className="Home valign halign">
            <div className="row">
                <div className="col s6">
                    <Card background={ catalogIcon }
                          title="Catalogue"
                          url="/catalog" />
                </div>
                <div className="col s6">
                    <Card background={ calculatorIcon }
                          title="Calculette"
                          url="/calculator" />
                </div>
            </div>
            <div className="row">
                <div className="col s6">
                    <Card background={ pharmacyIcon }
                          title="Pharmacies"
                          url="/pharmacy" />
                </div>
                <div className="col s6">
                    <Card background={ formsIcon }
                          title="Formulaire"
                          url="/survey" />
                </div>
            </div>
        </div>
    );
}
