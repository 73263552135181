import React, { Fragment } from 'react';

import './Modal.css';

export default function Modal(props) {
    const { open, title, content, onClose } = props;

    if (open) {
        return (
            <Fragment>
                <div className="Modal valign halign">
                    <h3>{ title }</h3>
                    { content }
                </div>
                <div className="Modal-overlay" onClick={ onClose }/>
            </Fragment>
        );
    } else return null;
}