import React, { Component } from 'react';
import history from "../../history";
import connect from 'react-redux/es/connect/connect';
import M from 'materialize-css';

import { saveSurvey } from "../../redux/actions/SurveyActions";
import { resetSurvey } from "../../redux/actions/SurveyActions";
import { fetchPharmacy } from "../../redux/actions/PharmacyActions";

import Alert from '../../components/AlertComponent/Alert';
import Modal from "../../components/ModalComponent/Modal";
import CircleProgress from "../PharmacyPage/Pharmacy";
import "./Survey.css";

class Survey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pharmacies: [],
            survey: {
                pharmacy: "",
                subject: "",
                comment: "",
                global: 5,
                product: 5,
                seller: 5
            }
        }
    }

    subjects = ["Questionnaire de satisfaction", "Service après-vente", "Autre"];

    componentDidMount() {
        const select = document.querySelectorAll('select');
        M.FormSelect.init(select);

        this.props.fetchPharmacy()
            .then(() => {
                this.setState(state => ({ ...state, pharmacies: this.props.pharmacies }))
            })
            .then(() => {
                const autocomplete = document.querySelectorAll('.autocomplete');
                M.Autocomplete.init(autocomplete, { data: this.state.pharmacies });
            });
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ ...this.state, survey: { ...this.state.survey, [name]: value } });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.saveSurvey(this.state.survey);
    };

    onClose = () => {
        this.props.resetSurvey();
        history.push("/");
    };

    render() {
        const { pharmacy, subject, comment, global, product, seller } = this.state.survey;
        const { surveySent, surveySending, surveySentError } = this.props;
        const subjects = this.subjects;
        const errorMessage = "Une erreur est survenue durant l'envoi du formulaire";

        return (
            <div className="Survey row">
                { surveySent && <Modal open
                                       content={ "Formulaire enregistré !" }
                                       onClose={ () => this.onClose() } /> }
                { surveySending && <CircleProgress /> }
                { surveySentError && <Alert message={ errorMessage } type="alert-danger" /> }
                { !surveySentError && !surveySending && !surveySent &&
                    <form className="Survey-form"
                          onSubmit={ this.handleSubmit }>
                        <h5 className="card-title black-text">Rédiger un compte rendu</h5>
                        <div className="input-field">
                            <input name="pharmacy"
                                   type="text"
                                   className="autocomplete"
                                   required
                                   value={ pharmacy }
                                   onChange={ this.handleChange } />
                            <label htmlFor="to">Pharmacie</label>
                        </div>
                        <div className="input-field">
                            <select name="subject"
                                    required
                                    value={ subject }
                                    onChange={ this.handleChange }>
                                <option disabled selected>Choisissez un sujet</option>
                                { subjects.map((value, key) => <option key={ key } value={ value }>{ value }</option>) }
                            </select>
                            <label htmlFor="subject">Sujet</label>
                        </div>
                        <div className="input-field">
                        <textarea name="comment"
                                  className="materialize-textarea"
                                  value={ comment }
                                  onChange={ this.handleChange } />
                            <label htmlFor="comment">Commentaire</label>
                        </div>
                        <div>
                            <h6>Comment évaluriez-vous :</h6>
                            <div className="col l4 s12">
                                <label htmlFor="global">Notre prestation globale ?</label>
                                <input name="global"
                                       type="range"
                                       className="Survey-range"
                                       min="0"
                                       max="5"
                                       value={ global }
                                       onChange={ this.handleChange } />
                                <label htmlFor="poduct">La qualité de nos produits ?</label>
                                <input name="product"
                                       type="range"
                                       className="Survey-range"
                                       min="0"
                                       max="5"
                                       value={ product }
                                       onChange={ this.handleChange } />
                                <label htmlFor="seller">La prestation de notre DMO ?</label>
                                <input name="seller"
                                       type="range"
                                       className="Survey-range"
                                       min="0"
                                       max="5"
                                       value={ seller }
                                       onChange={ this.handleChange } />
                            </div>
                        </div>
                        <button type="submit"
                                className={ "col s12 btn btn-large waves-effect " + process.env.REACT_APP_MAIN_THEME }>Envoyer
                        </button>
                    </form>
                }
            </div>
        );
    }
}

export default connect(state => ({
    pharmacies: state.pharmacy.pharmacies,
    surveySent: state.survey.surveySent,
    surveySending: state.survey.surveySending,
    surveySentError: state.survey.surveySentError
}), { fetchPharmacy, saveSurvey, resetSurvey })(Survey);