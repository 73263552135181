import React from 'react';

import './Alert.css';

export default function Alert(props) {
    const { message, type } = props;

    return (
        <p className={ "alert " + type }>{ message }</p>
    );
}