/**
 * PRODUCT ACTIONS
 */
export const FETCH_PRODUCT_START = 'FETCH_PRODUCT_START';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_ERROR = 'FETCH_PRODUCT_ERROR';

export const ADD_PRODUCT_START = 'ADD_PRODUCT_START';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_ERROR = 'ADD_PRODUCT_ERROR';

export const UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';

export const DELETE_PRODUCT_START = 'DELETE_PRODUCT_START';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';

/**
 * PHARMACY ACTIONS
 */
export const FETCH_PHARMACY_START = 'FETCH_PHARMACY_START';
export const FETCH_PHARMACY_SUCCESS = 'FETCH_PHARMACY_SUCCESS';
export const FETCH_PHARMACY_ERROR = 'FETCH_PHARMACY_ERROR';

export const ADD_PHARMACY_START = 'ADD_PHARMACY_START';
export const ADD_PHARMACY_SUCCESS = 'ADD_PHARMACY_SUCCESS';
export const ADD_PHARMACY_ERROR = 'ADD_PHARMACY_ERROR';

export const UPDATE_PHARMACY_START = 'UPDATE_PHARMACY_START';
export const UPDATE_PHARMACY_SUCCESS = 'UPDATE_PHARMACY_SUCCESS';
export const UPDATE_PHARMACY_ERROR = 'UPDATE_PHARMACY_ERROR';

export const DELETE_PHARMACY_START = 'DELETE_PHARMACY_START';
export const DELETE_PHARMACY_SUCCESS = 'DELETE_PHARMACY_SUCCESS';
export const DELETE_PHARMACY_ERROR = 'DELETE_PHARMACY_ERROR';

/**
 * SURVEY ACTIONS
 */
export const ADD_SURVEY_START = 'ADD_SURVEY_START';
export const ADD_SURVEY_SUCCESS = 'ADD_SURVEY_SUCCESS';
export const ADD_SURVEY_ERROR = 'ADD_SURVEY_ERROR';
export const RESET_SURVEY = 'RESET_SURVEY';

/**
 * USER ACTIONS
 */
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';