import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { fetchPharmacy } from '../../redux/actions/PharmacyActions';

import Alert from '../../components/AlertComponent/Alert';
import Button from '../../components/ButtonComponent/Button';
import CircleProgress from '../../components/CircleProgressComponent/CircleProgress';
import TablePharmacy from '../../components/TablePharmacyComponent/TablePharmacy';

class Pharmacy extends Component {
    componentDidMount() {
        this.props.fetchPharmacy();
    };

    render() {
        const { pharmacies, loadingInProgress, loadingError } = this.props;
        const reloadButtonText = "Raffraichir les données";
        const errorMessage = "Une erreur est survenue durant le chargement des données";

        return (
            <div className={ "text-center" }>
                    { loadingInProgress && <CircleProgress /> }
                    { loadingError && <Alert message={ errorMessage } type="alert-danger" /> }
                    { !loadingError && !loadingInProgress && <TablePharmacy data={ pharmacies } /> }
                <Button message={ reloadButtonText }
                        type={ process.env.REACT_APP_MAIN_THEME }
                        onClick={ fetchPharmacy } />
            </div>
        )
    }
}

export default connect(state => ({
    pharmacies: state.pharmacy.pharmacies,
    loadingInProgress: state.pharmacy.loadingInProgress,
    loadingError: state.pharmacy.loadingError
}), { fetchPharmacy })(Pharmacy);