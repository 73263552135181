import React from 'react';
import { withRouter } from 'react-router-dom';
import history from "../../history";

import './Header.css';

function getTitle(pathname) {
    switch (pathname) {
        case "/catalog":
            return "Catalogue";
        case "/calculator":
            return "Calculette";
        case "/pharmacy":
            return "Pharmacie";
        case "/survey":
            return "Formulaire de satisfaction";
        default:
            return "Pharmastock";
    }
}

function Header() {
    const pathname = history.location.pathname;
    const displayGoBack = pathname !== "/login" && pathname !== "/";
    const displayLogout = pathname !== "/login";
    const title = getTitle(pathname);

    return (
        <header className="Header">
            <nav>
                <div className={"nav-wrapper " + process.env.REACT_APP_MAIN_THEME }>
                    <div className="container">
                        { displayGoBack &&
                        <i className="Header-button left material-icons"
                                       onClick={ () => { history.goBack() } }>chevron_left</i>
                        }
                        <a href="/"
                           className="brand-logo center">{ title }</a>
                        { displayLogout &&
                        <a href="/logout"
                           className="Header-button right">Logout</a>
                        }
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default withRouter(Header);