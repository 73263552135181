import React from 'react';

import './Table.css';

export default function TableCatalog(props) {
    const { thead, tbody } = props;
    return (
        <table className="highlight">
            <thead>{ thead }</thead>
            <tbody>{ tbody }</tbody>
        </table>
    );
}