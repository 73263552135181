import React, { Fragment } from 'react';

import './ProductCalculator.css';

export default function ProductCalculator(props) {
    const { products } = props;

    const productsToDisplay = products.filter(product => product.count > 0);
    let totalPrice = 0;
    productsToDisplay.map(product => totalPrice += product.count * product.netSellingPrice);

    return (
        <div className="ProductCalculator col l4 s12">
            { !!productsToDisplay.length &&
                <Fragment>
                    <ul className="ProductCalculator-list text-left">
                        { productsToDisplay.map(product =>
                            <li key={ product._id } className="ProductCalculator-item">
                                <div className="ProductCalculator-item-detail">
                                <span>
                                    <span className={ "ProductCalculator-item-name " + process.env.REACT_APP_MAIN_TEXT_THEME }>{ product.name }</span>
                                    <span className="ProductCalculator-item-quantity"> x{ product.count }</span>
                                </span>
                                    <span
                                        className="ProductCalculator-item-price">{ (product.count * product.netSellingPrice).toFixed(2) } €</span>
                                </div>
                                <span className="ProductCalculator-item-info">Prix de vente unitaire : { product.netSellingPrice.toFixed(2) }</span>
                            </li>) }
                    </ul>
                    <div className="ProductCalculator-total text-right">
                        <p>Total : { totalPrice.toFixed(2) } €</p>
                    </div>
                </Fragment>
            }
            { !!productsToDisplay.length === false &&
                <h6 className="ProductCalculator-start text-center">Commencez à calculer !</h6>
            }
        </div>
    )
}