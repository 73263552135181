import React, { Component, Fragment } from 'react';
import M from 'materialize-css';

export default class Calculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operation: {},
            discountRate: 0,
            netPurchasePrice: 0,
            grossPurchasePrice: 0,
            netSellingPrice: 0,
            coefficient: 0,
            firstField: null,
            secondField: null
        };
        this.operations = [
            {
                name: "Taux de remise",
                firstField: "netPurchasePrice",
                secondField: "grossPurchasePrice"
            }, {
                name: "Prix d'achat net",
                firstField: "grossPurchasePrice",
                secondField: "discountRate"
            }, {
                name: "Prix de vente net",
                firstField: "netPurchasePrice",
                secondField: "coefficient"
            }, {
                name: "Coefficient multiplicateur",
                firstField: "netSellingPrice",
                secondField: "netPurchasePrice"
            }];
    }

    componentDidMount() {
        const select = document.querySelectorAll('select');
        M.FormSelect.init(select);
    }

    handleSelect = (e) => {
        const operations = this.operations;
        const value = e.target.value;
        const operation = operations.find(operation => { if(operation.name === value) return operation });

        this.setState({ ...this.state, operation: !!operation.name ? operation : {} });
    };

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (value >= 0) {
            this.setState({ ...this.state, [name]: value.replace(/^0+/, '') });
        }
    };

    Input = (props) => {
        const { discountRate, netPurchasePrice, grossPurchasePrice, netSellingPrice, coefficient } = this.state;
        switch (props.name) {
            case "discountRate":
                return (
                    <div className="input-field col s6">
                        <input name="discountRate"
                               type="number"
                               onChange={ this.handleChange }
                               value={ !!discountRate ? discountRate : 0 } />
                        <label htmlFor="discountRate" className="active">Taux de remise</label>
                    </div>
                );
            case "netPurchasePrice":
                return (
                    <div className="input-field col s6">
                        <input name="netPurchasePrice"
                               type="number"
                               onChange={ this.handleChange }
                               value={ !!netPurchasePrice ? netPurchasePrice : 0 } />
                        <label htmlFor="netPurchasePrice" className="active">Prix d'achat net</label>
                    </div>
                );
            case "grossPurchasePrice":
                return (
                    <div className="input-field col s6">
                        <input name="grossPurchasePrice"
                               type="number"
                               onChange={ this.handleChange }
                               value={ !!grossPurchasePrice ? grossPurchasePrice : 0 } />
                        <label htmlFor="grossPurchasePrice" className="active">Prix d'achat brut</label>
                    </div>
                );
            case "netSellingPrice":
                return (
                    <div className="input-field col s6">
                        <input name="netSellingPrice"
                               type="number"
                               onChange={ this.handleChange }
                               value={ !!netSellingPrice ? netSellingPrice : 0 } />
                        <label htmlFor="netSellingPrice" className="active">Prix de vente net</label>
                    </div>
                );
            case "coefficient":
                return (
                    <div className="input-field col s6">
                        <input name="coefficient"
                               type="number"
                               onChange={ this.handleChange }
                               value={ !!coefficient ? coefficient : 0 } />
                        <label htmlFor="coefficient" className="active">Coefficient multiplicateur</label>
                    </div>
                );
            default: return null;
        }
    };

    Formule = () => {
        const { operation } = this.state;
        switch (operation.name) {
            case "Taux de remise":
                return <p className="Calculator-expression blue-grey-text text-lighten-2">Formule: (1 - prix d'achat net / prix d'achat brut) * 100</p>;
            case "Prix d'achat net":
                return <p className="Calculator-expression blue-grey-text text-lighten-22">Formule: prix d'achat brut * (1 - taux de remise)</p>;
            case "Prix de vente net":
                return <p className="Calculator-expression blue-grey-text text-lighten-2">Formule: prix d'achat net * coefficient multiplicateur</p>;
            case "Coefficient multiplicateur":
                return <p className="Calculator-expression blue-grey-text text-lighten-2">Formule: prix d'achat net / prix d'achat brut</p>;
            default:
                return null;
        }
    };

    Result = () => {
        const { operation, discountRate, netPurchasePrice, grossPurchasePrice, netSellingPrice, coefficient } = this.state;
        switch (operation.name) {
            case "Taux de remise":
                const rate = (1 - netPurchasePrice / grossPurchasePrice) * 100;
                if (!!grossPurchasePrice === false && !!netPurchasePrice) return <p className="Calculator-error">Le prix d'achat brut ne peut pas être égale à 0</p>;
                return <p className="Calculator-total">Taux: { !!rate ? rate.toFixed(0) + " %" : 0 + " %" }</p>;
            case "Prix d'achat net":
                return <p className="Calculator-total">Prix d'achat net: { (grossPurchasePrice * (1 - discountRate)).toFixed(2) + "€" }</p>;
            case "Prix de vente net":
                return <p className="Calculator-total">Prix de vente net: { (netPurchasePrice * coefficient).toFixed(2) + "€" }</p>;
            case "Coefficient multiplicateur":
                const coeff = netSellingPrice / netPurchasePrice;
                if (!!netPurchasePrice === false && !!netSellingPrice) return <p className="Calculator-error">Le prix d'achat brut ne peut pas être égale à 0</p>;
                return <p className="Calculator-total">Coefficient: { !!coeff ? coeff.toFixed(0) + " %" : 0 + " %" }</p>;
            default:
                return null;
        }
    };

    render() {
        const { operation } = this.state;
        const { Input, Formule, Result } = this;
        const operations = this.operations;

        return (
            <div className="Calculator row">
                <div className="Login-form valign halign col l4 s12 card grey lighten-5">
                    <h5 className="card-title black-text">Calculatrice</h5>
                    <div className="input-field col s12">
                        <select name="operation"
                                required
                                value={ operation }
                                onChange={ this.handleSelect }>
                            <option>Choisissez une opération</option>)
                            { operations.map((operation, key) => <option key={ key } value={ operation.name }>{ operation.name }</option>) }
                        </select>
                        <label htmlFor="operation">Je souhaite calculer un...</label>
                    </div>
                    { !!operation.name &&
                    <Fragment>
                        <Input name={ operation.firstField }/>
                        <Input name={ operation.secondField }/>
                        <div className="text-right">
                            <Formule />
                            <Result />
                        </div>
                    </Fragment>
                    }
                </div>
            </div>
        );
    }
}