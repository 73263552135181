import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { fetchProduct } from '../../redux/actions/ProductActions';

import Alert from '../../components/AlertComponent/Alert';
import Button from '../../components/ButtonComponent/Button';
import ProductCalculator from "../../components/ProductCalculatorComponent/ProductCalculator";
import CircleProgress from '../../components/CircleProgressComponent/CircleProgress';
import TableCatalog from '../../components/TableCatalogComponent/TableCatalog';

class Catalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: []
        }
    }

    componentDidMount() {
        this.refreshData();
    };

    refreshData = () => {
        this.props.fetchProduct().then(() => this.setState(state => ({ ...state, products: this.props.products })));
    };

    handleChange = (e, id) => {
        const value = e.target.value;
        if (value >= 0) {
            this.setState(state => (
                {
                    ...state, products: state.products.map(product => {
                        if (product._id === id) product.count = value.replace(/^0+/, '');
                        return product;
                    })
                }
            ));
        }
    };

    render() {
        const { loadingInProgress, loadingError } = this.props;
        const { products } = this.state;
        const reloadButtonText = "Raffraichir les données";
        const errorMessage = "Une erreur est survenue durant le chargement des données";

        return (
            <div className={ "text-center" }>
                <div className="Catalog row">
                    <div className="col l8 s12">
                        { loadingError && <Alert message={ errorMessage } type="alert-danger" /> }
                        { !loadingError && !loadingInProgress &&
                        <TableCatalog data={ products } handleChange={ this.handleChange } /> }
                    </div>
                    <ProductCalculator products={ products } />
                </div>
                { loadingInProgress && <CircleProgress /> }
                <Button message={ reloadButtonText }
                        type={ process.env.REACT_APP_MAIN_THEME }
                        onClick={ this.refreshData } />
            </div>
        )
    }
}

export default connect(state => ({
    products: state.product.products,
    loadingInProgress: state.product.loadingInProgress,
    loadingError: state.product.loadingError
}), { fetchProduct })(Catalog);