import React, { Component, Fragment } from 'react';

import Modal from "../ModalComponent/Modal";
import Table from "../TableComponent/Table";

export default class TableCatalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: {}
        }
    }

    ModalContent = (props) => {
        const { netPurchasePrice, grossPurchasePrice, netSellingPrice } = props.product;
        return (
            <div>
                <p>Prix d'achat net : { netPurchasePrice.toFixed(2) } €</p>
                <p>Prix d'achat brut : { grossPurchasePrice.toFixed(2) } €</p>
                <p>Prix de vente net : { netSellingPrice.toFixed(2) } €</p>
                <p>Coefficient : { ((1 - netPurchasePrice / grossPurchasePrice) * 100).toFixed(0) } %</p>
                <p>Taux de remise : { (netSellingPrice / netPurchasePrice).toFixed(1) } %</p>
            </div>
        );
    };

    handleClick = (product) => {
        this.setState({ ...this.state, product: product })
    };


    render() {
        const { data, handleChange } = this.props;
        const { product } = this.state;
        const { ModalContent } = this;
        const thead =
            <tr>
                <th>Nom</th>
                <th>Px achat net</th>
                <th className="optional">Px achat brut</th>
                <th className="optional">Px vente net</th>
                <th>Quantité</th>
            </tr>;
        const tbody = data.map(row => {
            return (
                <tr key={ row._id } >
                    <td onClick={ () => this.handleClick(row) }>{ row.name }</td>
                    <td onClick={ () => this.handleClick(row) }>{ row.netPurchasePrice.toFixed(2) } €</td>
                    <td className="optional"
                        onClick={ () => this.handleClick(row) }>{ row.grossPurchasePrice.toFixed(2) } €</td>
                    <td className="optional"
                        onClick={ () => this.handleClick(row) }>{ row.netSellingPrice.toFixed(2) } €</td>
                    <td><input type="number"
                               onChange={ (e) => handleChange(e, row._id) }
                               value={ !!row.count ? row.count : 0 }/></td>
                </tr>
            );
        });

        return (
            <Fragment>
                { !!product.name && <Modal open title={ product.name }
                                           content={ <ModalContent product={product} /> }
                                           onClose={ () => this.setState({ ...this.state, product: {} }) } /> }
                <Table thead={ thead }
                       tbody={ tbody } />
            </Fragment>
        );
    }
}