import { ADD_SURVEY_START, ADD_SURVEY_SUCCESS, ADD_SURVEY_ERROR, RESET_SURVEY } from '../actionTypes';

const initialState = {
    surveySent: false,
    surveySending: false,
    surveySentError: false
};

export default function SurveyReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_SURVEY_START:
            return {
                ...state,
                surveySending: true,
            };
        case ADD_SURVEY_SUCCESS:
            return {
                ...state,
                surveySent: true,
                surveySending: false,
            };
        case ADD_SURVEY_ERROR:
            return {
                ...state,
                surveySending: false,
                surveySentError: true
            };
        case RESET_SURVEY:
            return {
                ...state,
                surveySent: false,
                surveySending: false,
                surveySentError: false
            };
        default:
            return state;
    }
}