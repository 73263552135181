import React, { Component, Fragment } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

class MapWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            // Position de Nantes
            pos: {
                lat: 47.218371,
                lng: -1.553621
            }
        }
    }

    componentDidMount() {
        const { google, pharmacy } = this.props;
        const address = pharmacy.name + " " + pharmacy.address + " " + pharmacy.postalCode + " " + pharmacy.city;

        const Geocoder = new google.maps.Geocoder();
        Geocoder.geocode({ address: address }, (res, status) => {
            if (status === 'OK') {
                this.setState({
                    ...this.state, pos: {
                        lat: res[0].geometry.location.lat(),
                        lng: res[0].geometry.location.lng()
                    }
                });
            }
        });
    }

    onMarkerClick = (props, marker) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    render() {
        const { google, pharmacy } = this.props;
        const { activeMarker, showingInfoWindow, selectedPlace, pos } = this.state;

        return (
            <Map google={ google }
                 zoom={ 14 }
                 center={ pos }
                 style={ { width: '100%', height: '100%', position: 'absolute' } }
                 onClick={ this.onClose }>
                <Marker onClick={ this.onMarkerClick }
                        name={ pharmacy.name }
                        position={ pos } />
                <InfoWindow
                    marker={ activeMarker }
                    visible={ showingInfoWindow }
                    onClose={ this.onClose }>
                    <div>
                        <h6>{ selectedPlace.name }</h6>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
})(MapWrapper);