import React, { Component, Fragment } from 'react';

import MapWrapper from "../../components/MapWrapperComponent/MapWrapper";
import Modal from "../ModalComponent/Modal";
import Table from "../TableComponent/Table";

import "./TablePharmacy.css";

export default class TablePharmacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pharmacies: props.data,
            pharmacy: {}
        }
    }

    ModalContent = (props) => {
        const { name, address, city, postalCode, idManager } = props.pharmacy;
        return (
            <div>
                <p>Nom : { name }</p>
                <p>Manager : { idManager.name + " - " + idManager.mail + " - " + idManager.phoneNumber }</p>
                <p>Adresse : { address + ", " + postalCode + " " + city }</p>
                <div className="PharmacyWrapper">
                    <MapWrapper pharmacy={ props.pharmacy } />
                </div>
            </div>
        );
    };

    handleClick = (pharmacy) => {
        this.setState({ ...this.state, pharmacy: pharmacy })
    };

    render() {
        const { data } = this.props;
        const { pharmacy } = this.state;
        const { ModalContent } = this;
        const thead =
            <tr>
                <th>Nom</th>
                <th className="optional">Adresse</th>
                <th className="optional">Manager</th>
            </tr>;
        const tbody = data.map(row => {
            return (
                <tr key={ row._id } onClick={ () => this.handleClick(row) }>
                    <td>{ row.name }</td>
                    <td className="optional">{ row.address + ", " + row.postalCode + " " + row.city }</td>
                    <td className="optional">{ row.idManager.name }</td>
                </tr>
            );
        });

        return (
            <Fragment>
                { !!pharmacy.name && <Modal open title={ pharmacy.name }
                                           content={ <ModalContent pharmacy={pharmacy} /> }
                                           onClose={ () => this.setState({ ...this.state, pharmacy: {} }) } /> }
                <Table thead={ thead }
                       tbody={ tbody } />
            </Fragment>
        );
    }
}