import { FETCH_PHARMACY_START, FETCH_PHARMACY_SUCCESS, FETCH_PHARMACY_ERROR } from "../actionTypes";

const initialState = {
    pharmacies: [],
    loadingInProgress: true,
    loadingError: false
};

export default function PharmacyReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PHARMACY_START:
            return {
                ...state,
                loadingInProgress: true,
                loadingError: false
            };
        case FETCH_PHARMACY_SUCCESS:
            return {
                ...state,
                pharmacies: action.data,
                loadingInProgress: false,
                loadingError: false
            };
        case FETCH_PHARMACY_ERROR:
            return {
                ...state,
                loadingInProgress: false,
                loadingError: true
            };
        default:
            return state;
    }
}