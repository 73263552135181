import axios from 'axios';
import { FETCH_PRODUCT_START, FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_ERROR } from '../actionTypes';

const apiURL = process.env.REACT_APP_API_URL + "product";

export const fetchProductStart = () => {
    return {
        type: FETCH_PRODUCT_START
    };
};

export const fetchProductSuccess = products => {
    return {
        type: FETCH_PRODUCT_SUCCESS,
        data: products
    };
};

export const fetchProductError = () => {
    return {
        type: FETCH_PRODUCT_ERROR
    };
};

export const fetchProduct = () => {
    return dispatch => {
        dispatch(fetchProductStart());
        return axios.get(apiURL)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else dispatch(fetchProductSuccess(res.data));
            })
            .catch(() => dispatch(fetchProductError()));
    }
};