import React, { Component } from 'react';
import history from "../../history";
import connect from "react-redux/es/connect/connect";
import { login } from '../../redux/actions/LoginActions';

import './Login.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            password: ""
        };
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ ...this.state, [name]: value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.login(this.state.name, this.state.password);
    };

    render() {
        const { token, authenticationInProgress, authenticationError } = this.props;
        const { name, password } = this.state;

        const loadingMessage = "Connexion en cours";
        const errorMessage = "Une erreur est survenue";
        if (!!token) {
            history.push("/");
            return null;
        } else {
            return (
                <div className="Login row">
                    <form className="Login-form valign halign col l3 s12 card grey lighten-5"
                          onSubmit={ this.handleSubmit }>
                        <h5 className="card-title black-text">Bienvenue</h5>
                        <div className="input-field">
                            <input name="name"
                                   type="text"
                                   className="validate"
                                   required
                                   value={ name }
                                   onChange={ this.handleChange } />
                            <label htmlFor="last_name">Last Name</label>
                        </div>
                        <div className="input-field">
                            <input name="password"
                                   type="password"
                                   className="validate"
                                   required
                                   value={ password }
                                   onChange={ this.handleChange } />
                            <label htmlFor="last_name">Password</label>
                        </div>
                        { authenticationInProgress && <p>{ loadingMessage }</p> }
                        { authenticationError && <p>{ errorMessage }</p> }
                        <button type="submit"
                                className={ "col s12 btn btn-large waves-effect " + process.env.REACT_APP_MAIN_THEME }>Connexion
                        </button>
                    </form>
                </div>
            );
        }
    }
}

export default connect(state => ({
    token: state.login.token,
    authenticationInProgress: state.login.authenticationInProgress,
    authenticationError: state.login.authenticationError
}), { login })(Login);