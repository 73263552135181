import axios from 'axios';
import { LOGIN_START, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from "../actionTypes";

const apiURL = process.env.REACT_APP_API_URL + "login";

export const loginStart = () => {
    return {
        type: LOGIN_START
    };
};

export const loginSuccess = token => {
    return {
        type: LOGIN_SUCCESS,
        data: token
    };
};

export const loginError = () => {
    return {
        type: LOGIN_ERROR
    };
};

export const login = (name, password) => {
    return dispatch => {
        dispatch(loginStart());
        return axios.post(apiURL, {
            name: name,
            password: password
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("User not authenticated");
                } else dispatch(loginSuccess(res.data.token));
            })
            .catch(() => dispatch(loginError()));
    }
};

export const logout = () => {
    return {
        type: LOGOUT
    }
};