import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from 'redux-thunk';

// Import Reducers
import ProductReducer from './reducers/ProductReducer';
import PharmacyReducer from './reducers/PharmacyReducer';
import LoginReducer from "./reducers/LoginReducer";
import SurveyReducer from "./reducers/SurveyReducer";

const reducers = combineReducers({
    product: ProductReducer,
    pharmacy: PharmacyReducer,
    login: LoginReducer,
    survey: SurveyReducer
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;