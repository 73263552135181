import React from 'react';

import './Button.css';

export default function Button(props) {
    const { message, type, onClick } = props;

    return (
        <button className={ "btn waves-effect waves-light " + type } onClick={ onClick }>{ message }</button>
    );
}